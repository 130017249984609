import insightsClient from 'search-insights'
import { ready } from '#js/components/utils'

insightsClient('init', {
  appId: globalThis.algoliaAppId,
  apiKey: globalThis.algoliaAPIKey,
  authenticatedUserToken: globalThis.userId
})

export function sendAlgoliaConversionEvent (element) {
  insightsClient('convertedObjectIDs', {
    authenticatedUserToken: globalThis.userId,
    index: 'event',
    eventName: element.dataset.algoliaConversionName,
    objectIDs: element.dataset.algoliaObjectIds.split(',').map(item => item.trim())
  })
}

export function algoliaEventsInit () {
  const algoliaConversionElement = document.getElementById('algolia-conversion')
  if (algoliaConversionElement !== null && window.location.href.match(/\/partners\/bookings\/completed\//)) {
    sendAlgoliaConversionEvent(algoliaConversionElement)
  }
}

ready(algoliaEventsInit)
